// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@tailwind base;
@tailwind components;
@tailwind utilities;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mazboletos-primary: mat.m2-define-palette(mat.$m2-blue-palette);
$mazboletos-accent: mat.m2-define-palette(mat.$m2-deep-orange-palette, A400);

$mazboletos-warn: mat.m2-define-palette(mat.$m2-red-palette);

$typography-config: mat.m2-define-typography-config(
  $font-family: 'Outfit, "Franklin Gothic Medium", "Arial Narrow", sans-serif',
  $button: mat.m2-define-typography-level(1.2rem, 1, 500),
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mazboletos-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $mazboletos-primary,
      accent: $mazboletos-accent,
      warn: $mazboletos-warn,
    ),
    typography: $typography-config,
  )
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($mazboletos-theme);
@include mat.typography-hierarchy($typography-config);

/* You can add global styles to this file, and also import other style files */

:root {
  --app-green: #{mat.m2-get-color-from-palette(mat.$m2-green-palette, 700)};
}

body,
html {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.scan-panel {
  width: 100vw !important;
  height: 100vh !important;
}
